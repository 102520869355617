import Profile from "./pages/Profile.js";
import { Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer.js";
import Navbar from "./Components/Navbar.js";
import Card from "./pages/Card.js";
import Lens from "./pages/Lens.js";
import NotFound from "./pages/NotFound.js";
import Shop from "./pages/Shop.js";
import Login from "./pages/Login.js";
import Manifesto from "./pages/Manifesto.js";
import Register from "./pages/Register.js";
import Home from "./pages/Home.js";
import NewProfile from "./pages/NewProfile.js";
import LinkedInRoaster from "./pages/LinkedInRoaster.js";
import { AuthProvider } from "./Components/AuthContext.js";
import ResumeTailor from "./pages/ResumeTailor/ResumeTailor.js";
import OpportunitiesSignUp from "./pages/OpportunitiesSignUp.js";

import HandlerProfile from "./B2B/Handler/HandlerProfile.js";
import PricingPage from "./pages/ResumeTailor/Pricing.js";
import GreeneProfile from "./B2B/Greene/GreeneProfile.js";
import Subscribe from "./pages/ResumeTailor/Subscribe.js";
import LinkAdmin from "./B2B/LinkAdmin.js";

function App() {
  return (
    <AuthProvider>
      <div className="min-h-screen flex flex-col justify-between ">
        <Navbar />
        <div className="flex-grow h-full w-full">
          <Routes>
            <Route path="/" element={<Card />} />
            <Route path="/lens" element={<Lens />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/profile/:id" element={<NewProfile />} />
            <Route path="/profile/:orgId/:id" element={<NewProfile />} />
            <Route path="/profile/handler/:id" element={<HandlerProfile />} />

            <Route path="/404" element={<NotFound />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/register/:id" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path="/manifesto" element={<Manifesto />} />
            <Route path="/linkedin" element={<LinkedInRoaster />} />
            <Route path="/resume" element={<ResumeTailor />} />
            <Route path="/resume/*" element={<ResumeTailor />} />
            <Route path="/magictailor/pricing" element={<PricingPage />} />
            <Route path="/magictailor/susbcribe" element={<Subscribe />} />
            <Route path="/opportunities" element={<OpportunitiesSignUp />} />
            <Route path="/admin/:orgId" element={<LinkAdmin />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer className="" />
      </div>
    </AuthProvider>
  );
}

export default App;
