import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";

export default function LinkModal({
  open,
  setOpen,
  title,
  initialLinkData,
  onSubmit,
}) {
  const [linkData, setLinkData] = useState(
    initialLinkData || { title: "", url: "", isActive: true }
  );

  //use effect to set initial data
  useEffect(() => {
    if (initialLinkData) {
      setLinkData(initialLinkData);
    } else {
      setLinkData({ title: "", url: "", isActive: true });
    }
  }, [initialLinkData]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLinkData({ ...linkData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(linkData);
    setOpen(false); // Close the modal after submission
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-10"
    >
      <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 w-full sm:max-w-sm sm:p-6">
            <div className="w-full">
              <div className="text-center">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={linkData.title}
                    onChange={handleInputChange}
                    className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:outline-blue-500 focus:outline sm:text-sm"
                    required
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    URL
                  </label>
                  <input
                    type="url"
                    name="url"
                    value={linkData.url}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md p-2 border-gray-300 shadow-sm focus:outline-blue-500 focus:outline  sm:text-sm"
                    required
                  />
                </div>

                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Active Status
                  </label>
                  <select
                    name="isActive"
                    value={linkData.isActive}
                    onChange={(e) =>
                      setLinkData({
                        ...linkData,
                        isActive: e.target.value === "true",
                      })
                    }
                    className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:outline-blue-500 focus:outline sm:text-sm"
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </div>

                <div className="mt-5 sm:mt-6">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-white font-medium shadow-sm hover:bg-blue-500"
                  >
                    {initialLinkData ? "Update Link" : "Add Link"}
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
