import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import {
  ArrowUpRightIcon,
  PencilSquareIcon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
  TrashIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import AddLinkModal from "./AddLinkModal";

function shortenString(str, length) {
  // Check if the string is longer than 15 characters
  if (!str) return "";
  if (str.length > length) {
    return str.slice(0, length) + "..."; // Return the first 15 characters with '...'
  }

  return str;
}

const LinkAdmin = () => {
  const [page, setPage] = useState("insights");
  const { orgId } = useParams();

  return (
    <div className="h-full w-full flex flex-col gap-4 py-8 items-center ">
      {/* Sticky Navbar */}
      <div
        className={`sticky top-2 flex bg-blue-200 text-blue-400 backdrop-blur-lg rounded-xl z-50 group`}
      >
        <button
          className={`py-4 px-4 rounded-lg transition-all duration-200 ${
            page === "insights"
              ? "bg-blue-500 text-white"
              : "bg-transparent hover:text-blue-800 hover:brightness-110 peer"
          }`}
          onClick={() => setPage("insights")}
        >
          Insights
        </button>
        <button
          className={`py-4 px-4 rounded-lg transition-all  duration-200 ${
            page === "manageLinks"
              ? "bg-blue-500 text-white"
              : "bg-transparent hover:text-blue-800 "
          }`}
          onClick={() => setPage("manageLinks")}
        >
          Tweak Your Links
        </button>
      </div>

      {/* Conditional Rendering of Components */}
      <div className="w-full flex flex-col items-center flex-grow gap-4 pt-8">
        {page === "insights" && <Insights orgId={orgId} />}
        {page === "manageLinks" && <ManageLinks orgId={orgId} />}
      </div>
    </div>
  );
};

const GreetingComponent = () => {
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("afternoon");
    } else {
      setGreeting("evening");
    }
  }, []); // Runs once on component mount

  return (
    <div className="text-xl self-start   bg-blue-50  p-2 mx-4 text-blue-700 justify-center  rounded-lg mb-4">
      <strong>Good </strong>
      {greeting}
    </div>
  );
};

const Insights = ({ orgId }) => {
  //axios post request to get links, pass orgId
  const [links, setLinks] = useState([]);
  const [org, setOrg] = useState({});
  const getLinks = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_org_links`,
        { organizationId: orgId }
      );
      if (response.status === 200) {
        setLinks(response.data);
      }
    } catch (error) {
      window.location.href = "/notfound";
      console.error("Error retrieving links:", error);
    }
  };

  const getOrgDetails = async (orgId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_org_details`,
        { organizationId: orgId }
      );
      if (response.status === 200) {
        setOrg(response.data);
      } else {
        window.location.href = "/notfound";
      }
    } catch (error) {
      window.location.href = "/notfound";
      console.error("Error retrieving organization details:", error);
    }
  };
  const fetchData = () => {
    getLinks();
    getOrgDetails(orgId);
    console.log("Data refreshed");
  };
  useEffect(() => {
    // Fetch data when component mounts
    fetchData();

    // Set up interval to refresh every 5 seconds
    const interval = setInterval(() => {
      fetchData();
    }, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const Stat = ({ children }) => {
    return (
      <div className="flex gap-2 items-baseline text-xl px-6">{children}</div>
    );
  };
  const Number = ({ children }) => {
    return <div className="4xl">{children}</div>;
  };

  return (
    <div className="lg:w-2/4 md:w-2/3 w-full justify-center bg-white rounded-lg text-sm flex flex-col gap-4">
      <GreetingComponent />
      <div className="pb-2 px-6 text-blue-600">Your latest insights</div>
      <div className="flex flex-col md:grid grid-cols-2 items-baseline  justify-between gap-4">
        <Stat className="">
          <span className="text-4xl font-semibold">{org.cardTaps}</span> Total
          card taps
        </Stat>
        <Stat className="">
          <span className="text-4xl font-semibold">{org.totalVisits || 0}</span>{" "}
          Total link clicks
        </Stat>
      </div>
      <div className="w-full bg-blue-50 p-8 md:rounded-xl ">
        <div className="font-semibold border-blue-400 text-blue-800 border-b-2 mb-8">
          Individual links
        </div>
        {links.map((link) => (
          <div
            key={link.name}
            className="w-full flex flex-col md:flex-row justify-between items-start py-2"
          >
            <div className="text-blue-800">{link.title}: </div>
            <div className="text-2xl font-semibold  text-blue-900">
              {link.linkVisits} clicks
            </div>
          </div>
        ))}
      </div>
      <Recommendations linkData={links} />
    </div>
  );
};

const ManageLinks = ({ orgId }) => {
  const [links, setLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  //Handle submit
  const handleSubmit = (newLink) => {
    insertLink(orgId, newLink);
  };

  //get links from server
  useEffect(() => {
    getLinks();
  }, []);

  //axios post request to get links, pass orgId
  const getLinks = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_org_links`,
        { organizationId: orgId }
      );
      if (response.status === 200) {
        setLinks(response.data);
      }
    } catch (error) {
      console.error("Error retrieving links:", error);
    }
  };

  //insert new link
  const insertLink = async (organizationId, link) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}insert_link`,
        {
          organizationId: organizationId,
          link: link,
        }
      );
      await getLinks();
    } catch (error) {
      console.error("Error inserting link:", error);
    }
  };

  //delete link
  const deleteLink = async (organizationId, linkId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}delete_link`,
        {
          organizationId: orgId,
          linkId: linkId,
        }
      );
      await getLinks();
    } catch (error) {
      console.error("Error deleting link:", error);
    }
  };

  const toggleLinkStatus = async (organizationId, link) => {
    try {
      const updatedLink = {
        ...link,
        isActive: !link.isActive,
      };
      console.log(updatedLink);
      await insertLink(organizationId, updatedLink);
    } catch {
      console.error("Error updating link:", error);
    }
  };

  const dummyLink = {
    title: "New Upcoming Event",
    url: "https://example.com/new-event",
    isActive: true,
    linkVisits: 0,
    linkClicksByUser: [],
    expirationDate: "2024-12-31T23:59:59.000Z",
    priority: 1,
    targetAudience: ["students", "researchers"],
    linkType: "event",
    callToActionText: "Join Now!",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    createdBy: { adminId: "ObjectId", name: "Admin Test" },
  };

  return (
    <div className="rounded-lg flex flex-col gap-4 w-full lg:w-2/4 md:w-2/3   justify-center text-black">
      <GreetingComponent />
      <AddLinkModal
        open={modalOpen}
        setOpen={setModalOpen}
        onSubmit={handleSubmit}
        title={selectedLink ? "Edit Link" : "Add New Link"}
        initialLinkData={selectedLink}
      />
      <div className="px-6">Manage links</div>
      <div className="flex flex-wrap md:grid grid-cols-2 gap-2 px-4 ">
        <div
          className="relative p-8 bg-slate-100 w-full items-center group hover:bg-slate-200  hover:cursor-pointer justify-center rounded-lg group text-sm font-light flex flex-col gap-3"
          onClick={() => {
            setSelectedLink(null); // For adding a new link
            setModalOpen(true);
          }}
        >
          <PlusIcon className="h-12 w-12 text-blue-800 group-hover:cursor-pointer group-hover:text-black group-hover:scale-110  transition-all duration-100" />
          <div className="text-blue-800 group-hover:cursor-pointer group-hover:text-black  transition-all duration-100">
            New link
          </div>
        </div>

        {links.map((link) => (
          <div className="relative p-8 bg-slate-100 hover:bg-slate-200 w-full rounded-lg group text-sm font-light flex flex-col gap-3">
            <div className="absolute top-4 right-4 flex flex-row gap-4 md:gap-2">
              {link.isActive ? (
                <EyeIcon
                  className="h-4 w-4 text-blue-800 hover:text-black hover:scale-110 hover:cursor-pointer transition-all duration-100"
                  onClick={() => toggleLinkStatus(orgId, link)}
                />
              ) : (
                <EyeSlashIcon
                  className="h-4 w-4 text-gray-400 hover:text-black hover:scale-110 hover:cursor-pointer transition-all duration-100"
                  onClick={() => toggleLinkStatus(orgId, link)}
                />
              )}
              <TrashIcon
                className="h-4 w-4 text-blue-800 hover:text-black hover:scale-110 hover:cursor-pointer transition-all duration-100"
                onClick={() => deleteLink(orgId, link._id)}
              />
              <PencilSquareIcon
                className="h-4 w-4 text-blue-800 hover:text-black hover:scale-110 hover:cursor-pointer transition-all duration-100"
                onClick={() => {
                  setSelectedLink(link);
                  console.log(selectedLink); // For editting existing link
                  setModalOpen(true);
                }}
              />
              <a href={link.url} target="_blank">
                <ArrowUpRightIcon className="h-4 w-4  text-blue-800 hover:text-black hover:scale-110 hover:cursor-pointer transition-all duration-100" />
              </a>
            </div>

            <div>
              <div className="font-semibold">{link.title}</div>
              <div className="text-xs">{shortenString(link.url, 25)}</div>
            </div>

            <div className="flex gap-1 text-xs flex-col">
              <div>
                <span className="font-semibold">Expiry:</span>{" "}
                {link.expirationDate}
              </div>
              <div>
                <span className="font-semibold">Clicks:</span> {link.linkVisits}
              </div>
            </div>
            <div
              onClick={() => toggleLinkStatus(orgId, link)}
              className={`inline-block px-2 py-1 self-start hover:cursor-pointer rounded-md text-white text-xs ${
                link.isActive ? "bg-green-500" : "bg-red-500"
              }`}
            >
              {link.isActive ? "Active" : "Not active"}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Recommendations = ({ linkData }) => {
  const sampleMessage = `Good work on the links! Here are a few suggestions to enhance engagement: \n 
Titles: Since the title is acting as the call to action, consider making them more descriptive or action-oriented. For example, instead of 'WCSA,' try 'Explore WCSA Services Today!' for better appeal.
Prioritization: Review the timing of events and make sure the most time-sensitive or high-impact events have higher priority.
Promotion: Consider increasing awareness of these links through other channels (emails, social media) to drive more visits.
Link engagement tracking: You may want to track which links get more attention closer to certain dates and adjust your content promotion strategy accordingly.`;

  const [recommendation, setRecommendation] = useState("");
  const getRecommendation = async (linkData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}get_link_insights`,
        { linkData: linkData }
      );
      if (response.status === 200) {
        setRecommendation(response.data);
      }
    } catch (error) {
      console.error("Error retrieving links:", error);
    }
  };

  useEffect(() => {
    getRecommendation(linkData);
  }, []);

  return (
    <div className="w-full flex flex-col bg-blue-50 bg-opacity-30 p-8 rounded-xl">
      <div className="font-semibold  flex gap-2 text-xs pb-4">
        <SparklesIcon className="h-4" />
        Recommendations
      </div>
      <pre className="text-xs whitespace-pre-wrap font-normal">
        {recommendation}
      </pre>
    </div>
  );
};

export default LinkAdmin;
