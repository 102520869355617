import React, { useEffect, useState } from "react";

import { ReactComponent as LinkedInIcon } from "../../Assets/img/icons/icons-01.svg";
import { ReactComponent as PhoneIcon } from "../../Assets/img/icons/icons-02.svg";
import { ReactComponent as EmailIcon } from "../../Assets/img/icons/icons-03.svg";
import { ReactComponent as InstagramIcon } from "../../Assets/img/icons/icons-04.svg";
import { ReactComponent as XIcon } from "../../Assets/img/icons/icons-05.svg";
import { ReactComponent as GithubIcon } from "../../Assets/img/icons/icons-06.svg";
import { ReactComponent as WhatsappIcon } from "../../Assets/img/icons/icons-07.svg";
import { ReactComponent as TiktokIcon } from "../../Assets/img/icons/icons-08.svg";
import { ReactComponent as FacebookIcon } from "../../Assets/img/icons/icons-09.svg";
import { ReactComponent as SnapchatIcon } from "../../Assets/img/icons/icons-10.svg";
import { ReactComponent as WebsiteIcon } from "../../Assets/img/icons/icons-11.svg";
import { ReactComponent as Arrow } from "../../Assets/img/icons/arrow.svg";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useParams } from "react-router-dom";

function ensureHttps(url) {
  // Check if the URL already starts with 'https://' or 'http://'
  if (!url.startsWith("https://") && !url.startsWith("http://")) {
    return `https://${url}`; // Prepend 'https://' if missing
  }
  return url; // Return the original URL if it already has the correct protocol
}

const NewProfileCard = ({ person, links }) => {
  //increment link visits

  const { orgId } = useParams();
  const increment_visits = async (orgId, linkId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_BACKEND_URL}increment_visits`,
        { orgId: orgId, linkId: linkId }
      );
      if (response.status === 200) {
        console.log("Link visits incremented");
      }
    } catch (error) {
      console.error("Error incrementing link visits:", error);
    }
  };
  const getImageType = (imageUrl) => {
    const extension = imageUrl.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "JPEG";
      case "png":
        return "PNG";
      case "gif":
        return "GIF";
      default:
        return "JPEG"; // Default to JPEG if you cannot determine
    }
  };

  function downloadVCard(person) {
    const firstName = person.firstName || "";
    const lastName = person.lastName || "";
    const fullName = person.firstName + person.lastName || "";
    const telephone = person.phone || "";
    const email = person.email || "";
    const linkedIn = person.linkedIn || "";
    const x = person.x || "";
    const github = person.github || "";
    const instagram = person.instagram || "";
    const portfolio = person.portfolio || "";
    const imageUrl = person.imageUrl || "";
    const imageType = getImageType(imageUrl);
    const resume = person.resume || "";
    const headline = person.headline || "";

    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${lastName};${firstName};;;`, // Surname; Given name; Additional names; Honorific prefixes; Honorific suffixes
      `FN:${fullName}`,
      `TITLE:${headline}`,
      `TEL;TYPE=CELL:${telephone}`,
      `EMAIL:${email}`,
      `URL:${portfolio}`,
      `X-SOCIALPROFILE;type=linkedIn;x-user=${linkedIn}`,
      `PHOTO;TYPE=${imageType};VALUE=URI:${imageUrl}`,
      "END:VCARD",
    ].join("\r\n");

    const blob = new Blob([vCardData], { type: "text/vcard" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${person.firstName}.vcf`; // Name of the file to download
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  const copyToClipboard = async (textToCopy) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      //alert to say success
      alert("Copied to clipboard");
    } catch (err) {
      alert(`Failed to copy. Try highlighting ${textToCopy} and select copy`);
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    // Remove all non-numeric characters
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");

    // Check if the cleaned number has the correct length (10 digits)
    if (cleaned.length !== 10) {
      return phoneNumberString; // Return the original input if it's not a valid 10-digit number
    }

    // Format the number
    const formatted = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
      3,
      6
    )}-${cleaned.slice(6)}`;

    return formatted;
  }

  function shortenString(str, length) {
    // Check if the string is longer than 15 characters
    if (str.length > length) {
      return str.slice(0, length) + "..."; // Return the first 15 characters with '...'
    }

    return str; // Return the
  }

  console.log(person);

  return (
    <div className="min-h-screen   w-full flex flex-col bg-black">
      <div className="h-32 w-full bg-sky-600 relative">
        <div className="absolute overflow-hidden h-32 w-full mix-blend-multiply">
          <img
            src={require("../../Assets/img/bg-banner.png")}
            alt="background-pattern"
            className="h-44 w-full object-cover"
          />
        </div>
      </div>

      <div className="w-full lg:w-2/3 lg:mx-auto p-8 -translate-y-24 flex flex-col items-start gap-8">
        <div className="w-full flex justify-between items-start">
          <div className="h-32 w-32 outline bg-black outline-2 relative overflow-hidden rounded-full">
            <img
              src={person.imageUrl}
              className="h-full w-full object-cover object-top"
            />
          </div>
          <div className="md:flex gap-2 grid grid-cols-1">
            {person.resume && <ViewResume person={person} />}

            {/*Placeholder for second menue */}
          </div>
        </div>

        <div className="w-full flex flex-col items-start text-white gap-1">
          <div className="text-3xl">
            {person.firstName}{" "}
            <span className="font-bold">{person.lastName}</span>
          </div>
          {person.headline ? (
            <div className="pl-0.5">{person.headline}</div>
          ) : (
            <>
              <div className="">
                {person.position && person.position}{" "}
                {person.position && person.company && " @ "}
                {person.company && `${person.company}`}
              </div>
              <div className="">
                {person.major && person.major}
                {person.major && person.college && " @ "}
                {person.college && person.college}
              </div>
            </>
          )}
        </div>
        <div className="flex flex-row gap-4 justify-start  w-full font-extralight">
          <div
            className="py-4 md:px-10 px-4  flex flex-grow max-w-64 text-center items-center justify-center   bg-sky-600  cursor-pointer hover:bg-sky-500 text-white rounded-lg"
            onClick={() => downloadVCard(person)}
          >
            Save contact
          </div>
          {/* <div className="py-4 md:px-10 px-4 bg-stone-200 flex-grow max-w-64 text-black text-center items-center justify-center cursor-pointer hover:bg-stone-100 rounded-lg">
            Share <strong>your</strong> contact
          </div> */}
        </div>
        <div className="flex text-white text-sm flex-col gap-2 md:grid md:grid-cols-2 w-full -translate-x-2">
          {person.portfolio && (
            <a
              href={ensureHttps(person.portfolio)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <WebsiteIcon className="h-14" />
              <div className="flex  flex-col items-start">
                <div>Website</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.portfolio, 30)}
                </div>
              </div>
            </a>
          )}
          {person.linkedIn && (
            <a
              href={ensureHttps(person.linkedIn)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <LinkedInIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>LinkedIn</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.linkedIn, 40)}
                </div>
              </div>
            </a>
          )}
          {person.github && (
            <a
              href={ensureHttps(person.github)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100  hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <GithubIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>GitHub</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.github, 40)}
                </div>
              </div>
            </a>
          )}
          {person.email && (
            <a
              href={`mailto:${person.email}`}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <EmailIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>Email</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.email, 50)}
                </div>
              </div>
            </a>
          )}
          {person.phone && (
            <div
              className="flex gap-4 opacity-80 hover:opacity-100 hover:cursor-pointer hover:bg-stone-900 rounded-xl p-2 w-full"
              onClick={() => copyToClipboard(person.phone)}
            >
              <PhoneIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>Phone</div>
                <div className="text-xs text-stone-200 font-light">
                  {formatPhoneNumber(person.phone)}
                </div>
              </div>
            </div>
          )}

          {person.instagram && (
            <a
              href={ensureHttps(person.instagram)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <InstagramIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>Instagram</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.instagram, 35)}
                </div>
              </div>
            </a>
          )}
          {person.whatsapp && (
            <a
              href={person.whatsapp}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <WhatsappIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>WhatsaApp</div>
                <div className="text-xs text-stone-200 font-light">
                  {person.whatsapp}
                </div>
              </div>
            </a>
          )}
          {person.snapchat && (
            <a
              href={ensureHttps(person.snapchat)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <SnapchatIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>WhatsaApp</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.snapchat, 35)}
                </div>
              </div>
            </a>
          )}
          {person.tiktok && (
            <a
              href={ensureHttps(person.tiktok)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <TiktokIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>TikTok</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.tiktok, 35)}
                </div>
              </div>
            </a>
          )}
          {person.x && (
            <a
              href={ensureHttps(person.x)}
              target="_blank"
              className="flex gap-4 opacity-80 hover:opacity-100 hover:bg-stone-900 rounded-xl p-2 w-full"
            >
              <XIcon className="h-14" />
              <div className="flex flex-col items-start">
                <div>X</div>
                <div className="text-xs text-stone-200 font-light">
                  {shortenString(person.x, 35)}
                </div>
              </div>
            </a>
          )}
        </div>
        {links && links.some((link) => link.isActive) && (
          <div className="text-stone-400 flex flex-col justify-start items-start ">
            <img
              src={require("../../Assets/img/icons/icons-13.png")}
              className="h-10 object-cover"
            />
            <p className="pb-2">
              From {orgId.charAt(0).toUpperCase() + orgId.slice(1)}{" "}
            </p>
            <div className="flex flex-col gap-2">
              {links.map((link) => {
                return (
                  link.isActive && (
                    <a
                      href={link.url}
                      onClick={() => increment_visits(orgId, link._id)}
                      target="_blank"
                      className="text-stone-400 underline text-sm hover:text-white hover:underline underline-offset-2"
                    >
                      {link.title}
                    </a>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewProfileCard;

const ViewResume = ({ person }) => {
  const viewLater = async () => {
    //take email
    //send to backend
  };
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState("");

  const handleEmailSend = async (e) => {
    e.preventDefault();
    try {
      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert("Please enter a valid email address.");
        return;
      }
      setShowEmailInput(false);

      try {
        setShowEmailInput(false);
        const body = {
          shareTo: email,
          shareFrom: person.email,
          resume: person.resume,
          firstName: person.firstName,
          lastName: person.lastName,
          imageUrl: person.imageUrl,
          headline: person.headline,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_PUBLIC_BACKEND_URL}share_resume_email`,
          body
        );
        console.log("Resume sent");
        // send alert
        alert("Resume sent");
      } catch (error) {
        console.error(error);
      } finally {
        setShowEmailInput(false);
      }

      console.log("Resume sent");
      // send alert
      alert("Resume sent");
    } catch (error) {
      console.error(error);
    } finally {
      setShowEmailInput(false);
    }
  };
  return (
    <div>
      <Menu
        as="div"
        className="relative inline-block text-left translate-y-20 w-full"
      >
        <div>
          <Menu.Button className="bg-yellow-200 cursor-pointer text-black hover:bg-yellow-100 font-light rounded-lg py-1 px-4 text-sm">
            View <strong>resume</strong>
          </Menu.Button>
        </div>

        <Menu.Items
          transition
          className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-yellow-50 shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="">
            <Menu.Item>
              {({ active }) => (
                <a
                  href={ensureHttps(person.resume)}
                  target="_blank"
                  className={`block px-4 py-2 text-sm text-gray-700 ${
                    active ? "bg-yellow-100" : ""
                  }`}
                >
                  Open link
                </a>
              )}
            </Menu.Item>
            <form action="#" method="POST">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setShowEmailInput(true)}
                    className={`block w-full px-4 py-2 text-left text-sm text-gray-700 ${
                      active ? "bg-yellow-100" : ""
                    }`}
                  >
                    View later
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Menu>
      {showEmailInput && (
        <div className="absolute z-10 inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-8">
          <div className="bg-white p-4 py-8 w-full max-w-md flexflex-col items-center justify-center rounded-lg shadow-md">
            <div className="py-2 px-2 text-sm">Receive resume in email</div>
            <form onSubmit={handleEmailSend}>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="bg-slate-100 font-extralight p-2 text-sm rounded-lg focus:border outline-none border-blue-700 min-w-[20px] text-black w-full"
              />
              <div className="flex gap-2 item-end mt-2">
                <button
                  onClick={() => setShowEmailInput(false)}
                  className="bg-gray-200 text-xs text-gray-800 hover:bg-gray-300 px-2 py-2 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-xs text-gray-50 hover:text-white px-4 py-2 rounded-lg"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
